<template>
  <router-view/>
</template>

<style>
#app {
  font-family: Helvetica, Avenir, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
body{
  margin-left: 40px;
  margin-right: 40px;
  background: linear-gradient(90deg, #1B1B1B 0%, #373737 100%);
  color: #9c9c9c;
}
</style>
